import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouter } from 'next/router';

/**
 * Components
 */
import Snackbar from '@pedidosya/web-fenix/molecules/Snackbar';

/**
 * Constants
 */
import ROUTES from '@/src/constants/routes';
import { FIXED_FOOTER_ID } from '@/src/components/ShellFooter';

/**
 * Utils
 */
import useIsDesktop from '@/src/hooks/useIsDesktopLayout';

const ToastRefId = 'toast-ref';
const ToastRef = styled.div``;

export const ToastContext = createContext({});
ToastContext.displayName = 'ToastContext';

function usePrevious(value) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const types = {
  INFORMATIVE: 'informative',
  ERROR: 'error',
  POSITIVE: 'positive',
  WARNING: 'warning',
  NONE: 'informative',
};

const noToast = { type: types.NONE, message: '' };

function ToastProvider({ children }) {
  const [toast, setToast] = useState(noToast);
  const previousToast = usePrevious(toast);
  const isDesktop = useIsDesktop();
  const toastRef = useRef(null);
  const router = useRouter();
  const isHomeRoute = router.pathname === ROUTES.HOME;

  const close = useCallback(() => setToast(noToast), [setToast]);
  const error = useCallback(
    (message) => setToast({ type: types.ERROR, message }),
    [setToast],
  );
  const info = useCallback(
    (message) => setToast({ type: types.INFORMATIVE, message }),
    [setToast],
  );
  const success = useCallback(
    (message) => setToast({ type: types.POSITIVE, message }),
    [setToast],
  );

  const value = useMemo(
    () => ({ error, info, success, setToast }),
    [error, info, success, setToast],
  );

  const hasToast = toast.type !== types.NONE;
  const toastToBeShown = hasToast ? toast : previousToast;

  useEffect(() => {
    const positionSnackbarElement = toastRef.current?.querySelector(
      `#${ToastRefId} > div`,
    );
    const isToastShownInNonDesktopDevice =
      hasToast && positionSnackbarElement && !isDesktop;
    if (isToastShownInNonDesktopDevice) {
      const fixedFooterElementHeight =
        document.getElementById(FIXED_FOOTER_ID)?.clientHeight ?? 0;
      positionSnackbarElement.style.bottom =
        fixedFooterElementHeight > 0 && !isHomeRoute
          ? `${fixedFooterElementHeight}px`
          : null;
    }

    return () => {
      if (positionSnackbarElement) positionSnackbarElement.style.bottom = null;
    };
  }, [hasToast, isDesktop, isHomeRoute]);

  useEffect(() => {
    let timeoutHandler;
    if (toast) {
      timeoutHandler = setTimeout(() => {
        close();
      }, 4000);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [close, toast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastRef id={ToastRefId} ref={toastRef}>
        <Snackbar
          type={toastToBeShown.type}
          label={toastToBeShown.message}
          position={isDesktop ? 'top-right' : 'bottom-center'}
          show={hasToast}
          onClose={close}
          onClickAction={() => {}}
        />
      </ToastRef>
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastProvider;
