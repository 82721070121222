import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import isServer from '@/src/utils/isServer';
import { getWindowObject } from '@/src/utils/documentWindow';

export const WindowContext = createContext(null);

function WindowProvider({ children }) {
  // Set default to null to prevent server html differ from hydrate html.
  const [w, setWindow] = useState(null);

  const fromServer = isServer();
  useEffect(() => {
    if (!fromServer) setWindow(getWindowObject);
  }, [fromServer]);

  return <WindowContext.Provider value={w}>{children}</WindowContext.Provider>;
}

WindowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowProvider;
