import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext({});

/**
 * Provider for the current App to store the initial response from SSR
 * @param {object} params
 */
const AppProvider = ({ children, initialState }) => (
  <AppContext.Provider value={initialState}>{children}</AppContext.Provider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
