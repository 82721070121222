import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

/**
 * Constants
 */
import { COUNTRY_SHORT_NAME } from '@appscore/web-constants/countries';

const CountryContext = createContext({});

function CountryProvider({ children, countryShortName }) {
  return (
    <CountryContext.Provider value={countryShortName}>
      {children}
    </CountryContext.Provider>
  );
}

function useCountryShortName() {
  const context = useContext(CountryContext);
  if (context === undefined) {
    throw new Error('useCountry must be used within an CountryProvider');
  }

  return context;
}

CountryProvider.propTypes = {
  children: PropTypes.node.isRequired,
  countryShortName: PropTypes.oneOf(Object.values(COUNTRY_SHORT_NAME))
    .isRequired,
};

export { CountryProvider, useCountryShortName };
