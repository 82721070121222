import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ConsumerContext = createContext({});

function ConsumerProvider({ children, consumer }) {
  return (
    <ConsumerContext.Provider value={consumer}>
      {children}
    </ConsumerContext.Provider>
  );
}

function useConsumer() {
  const context = useContext(ConsumerContext);

  return context;
}

ConsumerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  consumer: PropTypes.object,
};

export { ConsumerProvider, useConsumer };
