// imports
import React from 'react';
import ReactDOM from 'react-dom';
import isServer from '@/src/utils/isServer';

// export function
// get parent and className props as well as the children
// eslint-disable-next-line react/prop-types
export default function Portal({ children, parent, open }) {
  // Create div to contain everything
  const el = React.useMemo(
    () => (!isServer() ? document.createElement('div') : null),
    [],
  );
  // On mount function
  React.useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';

    // work out target in the DOM based on parent prop
    // eslint-disable-next-line react/prop-types
    const target = parent && parent.appendChild ? parent : document.body;
    // Append element to dom
    target.appendChild(el);
    // On unmount function
    return () => {
      // Remove element from dom
      target.removeChild(el);
      // make sure overflow is off
      document.body.style.overflow = 'unset';
    };
  }, [el, parent, open]);

  if (!isServer()) {
    // return the createPortal function
    return ReactDOM.createPortal(children, el);
  }

  return null;
}
