import { createGlobalStyle } from 'styled-components';

const GlobalStyleSheet = createGlobalStyle`
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Segma', sans-serif;
  font-size: 12px;

  & #__next {
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
`;

export default GlobalStyleSheet;
