import React from 'react';
import PropTypes from 'prop-types';

import Container from './components/Container';
import Backdrop from './components/Backdrop';
import Portal from './Portal';

function Modal({ open, children, onClose }) {
  return (
    <Portal open={open}>
      {open && (
        <Backdrop onClick={onClose}>
          <Container onClick={(e) => e.stopPropagation()}>{children}</Container>
        </Backdrop>
      )}
    </Portal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Modal.defaultProps = {};

export default Modal;
