import axios from 'axios';
import axiosRetry from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed';

let instance = null;

function isNetworkError(error) {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

const retryInterceptor = (axiosInstance) =>
  axiosRetry(axiosInstance, {
    retries: 2,
    retryCondition: isNetworkError,
    retryDelay: axiosRetry.exponentialDelay,
  });

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();
  retryInterceptor(instance);

  return instance;
}

const getInstance = (props) => instance || initialize(props);

export default getInstance;
