import fenixTheme from '@pedidosya/web-fenix/theme';

import zIndex from './position';
import { colors } from './colors';

export const LATERAL_SPACE_PERCENTAGE = 4;

const localTheme = {
  constants: {
    lateralSpace: `${LATERAL_SPACE_PERCENTAGE}%`,
    zIndex,
  },
  irl: {
    textColor: colors.white,
    googleLogin: 'rgb(45, 134, 244)',
    facebookLogin: 'rgb(59, 89, 152)',
    appleLogin: 'rgb(27, 37, 35)',
  },
};

const composedTheme = {
  ...fenixTheme,
  commons: {
    input: {
      background: '$white',
      border: '1px solid $night_blue_3',
      borderRadius: '34px',
      focusColor: '$deep_purple',
      successColor: 'rgb(1, 201, 140)',
      errorColor: '$red_darker_2',
      sandElevation: '0 8px 34px -25px $sepia_80',
      whiteElevation: '0 3px 14px -8px $sepia_20',
    },
    bottomSheet: {
      elevation: '0 -32px 84px -10px $grape_50',
      handlerColor: '$platinum',
    },
  },
  colors: {
    ...colors,
  },
  ...localTheme,
};

export default composedTheme;
