const GLOBAL_HEADERS = {
  APP_PLATFORM: 'Peya-App-Platform',
  X_APP_PLATFORM: 'X-App-Platform',
  APP_VERSION: 'Peya-App-Version',
  X_APP_VERSION: 'X-App-Version',
  DEVICE_ID: 'Peya-Device-ID',
  GLOBAL_ENTITY_ID: 'Peya-Global-Entity-ID',
  SESSION_ID: 'Peya-Session-ID',
  SESSION_TIMESTAMP: 'Peya-Session-Timestamp',
  TRACE_ID: 'Peya-Trace-ID',
  AUTHORIZATION: 'Authorization',
  PERSEUS_CLIENT_ID: 'Peya-Perseus-Client-ID',
  PERSEUS_SESSION_ID: 'Peya-Perseus-Session-ID',
  PERSEUS_HITMATCH_ID: 'Peya-Perseus-Hitmatch-ID',
  WHITE_LABEL: 'Peya-WhiteLabel-ID',
  USER_AGENT: 'User-Agent',
  REQUEST_TYPE: 'Peya-Request-Type',
  CLIENT_IP: 'Peya-Client-Ip',
};

module.exports = GLOBAL_HEADERS;
