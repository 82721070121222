import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isServer from '@/src/utils/isServer';

/**
 * Components
 */
// import Modal from '@pedidosya/web-fenix/foundations/Layout/Modal'; | Fenix modal use when max-width is fixed
import Modal from '@/src/components/Modal';
import parseUserAgent from '@appscore/device-detector';

export const DeviceContext = createContext(null);

const Container = styled.div`
  background-color: #f9f6f4;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.red};
`;

function useDevice() {
  const [device, setDevice] = useState(() => {
    if (typeof window === 'undefined') {
      return 'DESKTOP';
    }

    return parseUserAgent(window.navigator.userAgent).deviceType;
  });

  const handleResize = useCallback(() => {
    const { deviceType } = parseUserAgent(window.navigator.userAgent);
    setDevice(deviceType);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return device;
}

function DeviceProvider({ children }) {
  const isSSR = isServer();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentDevice = isSSR ? 'DESKTOP' : useDevice();

  if (!hydrated) {
    // Render a placeholder to prevent mismatched HTML
    return <div>Loading...</div>;
  }

  return (
    <DeviceContext.Provider value={currentDevice}>
      {currentDevice === 'DESKTOP' ? (
        <>
          <Header />
          <Modal open>
            <Container>{children}</Container>
          </Modal>
        </>
      ) : (
        children
      )}
    </DeviceContext.Provider>
  );
}

DeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DeviceProvider;
