import * as React from 'react';
import PropTypes from 'prop-types';

/**
 * Utils
 */
import isVariation from '@/src/utils/fwf/isVariation';

const FunWithFlagsContext = React.createContext({});
FunWithFlagsContext.displayName = 'FunWithFlagsContext';
function FunWithFlagsProvider({ children, flags: flagNames }) {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FunWithFlagsContext.Provider value={{ flags: flagNames }}>
      {children}
    </FunWithFlagsContext.Provider>
  );
}

FunWithFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.object,
};

function useFunWithFlagsApi() {
  const context = React.useContext(FunWithFlagsContext);
  if (context === undefined) {
    throw new Error(
      'useFunWith Flags must be used within an FunWithFlagsProvider',
    );
  }

  return context.fwf;
}

function useFlags() {
  const context = React.useContext(FunWithFlagsContext);
  if (context === undefined) {
    throw new Error('useFlags must be used within an FunWithFlagsProvider');
  }

  return context?.flags || {};
}

function useFlag(name, fallback) {
  const flags = useFlags();

  return flags[name] ?? { variation: fallback };
}

function useFlagVariation(name, fallback) {
  const flag = useFlag(name, fallback);
  return flag.variation;
}

function useIsVariation(name, fallback) {
  const flag = useFlag(name, fallback);

  return isVariation(flag);
}

export {
  FunWithFlagsProvider,
  useFlag,
  useFlagVariation,
  useIsVariation,
  useFunWithFlagsApi,
};
