/* eslint-disable no-param-reassign */
/**
 * Check if the current param is an instance object (No Array, No Date)
 * @returns boolean
 */
export const isObject = (obj) =>
  obj &&
  typeof obj === 'object' &&
  !Array.isArray(obj) &&
  !(obj instanceof Date);

export const pick = (fromObject, properties = []) =>
  fromObject &&
  properties.reduce((p, c) => {
    if (Object.prototype.hasOwnProperty.call(fromObject, c)) {
      p[c] = fromObject[c];
    }
    return p;
  }, {});

export function ignore(map = {}, propsToFilter = []) {
  return Object.keys(map).reduce((acc, key) => {
    if (propsToFilter.indexOf(key) === -1) acc[key] = map[key];
    return acc;
  }, {});
}

export function isNullOrUndefined(o) {
  return o === null || o === undefined;
}

export function hasValue(o) {
  return !isNullOrUndefined(o) && o !== '';
}
