import { useContext } from 'react';

/**
 * Providers
 */
import { DeviceContext } from '@/src/providers/DeviceProvider';

function useDeviceType() {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error(`useDeviceType must be used within an DeviceProvider`);
  }

  return context;
}

export default useDeviceType;
