var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"web-micro-site-login@2.5.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/webassets/IRL-Web/login/2.5.4";

const Sentry = require('@sentry/nextjs');
const applicationName = require('./config/applicationName');
const applicationVersion = require('./config/applicationVersion');

const SENTRY_DSN =
  'https://d71da7c6f5b142c5986238d6eeec706d@o4504046939799552.ingest.sentry.io/4505329977458688';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  sampleRate: 0.1,
  environment:
    window.location.host === 'authenticate.pedidosya.com' ||
    window.location.host === 'pedidosya.com'
      ? 'live'
      : 'stg',
  release: `${applicationName}@${applicationVersion}`,
  integrations: [
    new Sentry.BrowserTracing({
      beforeNavigate: (context) => ({
        ...context,
      }),
    }),
  ],
});
