import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    box-sizing: border-box;
    border-radius: ${theme.borderRadius('border-radius-default')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 550px;
  `,
);

export default Container;
