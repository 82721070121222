import React from 'react';
import PropTypes from 'prop-types';
import Error from '@pedidosya/web-fenix/pages/Error';
import { logError } from '@/src/utils/sentry';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: true,
    };
  }

  componentDidCatch(error) {
    this.setState({
      showComponent: false,
    });
    logError('[APP_ERROR]', error);
  }

  render() {
    const { showComponent } = this.state;
    const { children, fallback } = this.props;
    return showComponent ? children : fallback || <Error errorCode={500} />;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default ErrorBoundary;
