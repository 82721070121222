import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';

import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';

/**
 * Styles
 */
import GlobalStyleSheet from '@/src/styles/globalStyleSheet';

/**
 * Components
 */
import ErrorBoundary from '@/src/components/ErrorBoundary';

/**
 * Utils
 */
import { initialize as initializeAxios } from '@/external-libs/axios';
import theme from '@/src/theme';

/**
 * Providers
 */
import AppProvider from '@/src/providers/AppProvider';
import WindowProvider from '@/src/providers/WindowProvider';
import { ConsumerProvider } from '@/src/providers/ConsumerProvider';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import DeviceProvider from '@/src/providers/DeviceProvider';
import ToastProvider from '@/src/providers/ToastProvider';
import { FunWithFlagsProvider } from '@/src/providers/FunWithFlagsProvider';
import { CountryProvider } from '@/src/providers/CountryProvider';

import favicon from '../assets/favicon.ico';
import GLOBAL_HEADERS from '../constants/headers';

function handleOnErrorTranslations() {
  // Avoid missmatch for SSR in local
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function getGlobalEntityIdHeader(headers) {
  const peyaHeaders = Object.entries(headers).filter(([key]) =>
    key.includes(GLOBAL_HEADERS.GLOBAL_ENTITY_ID),
  );
  return Object.fromEntries(peyaHeaders);
}

function App({ Component, pageProps }) {
  const {
    initialState,
    i18n: { locale, messages } = {},
    axios: { headers } = { headers: {} },
  } = pageProps;
  const country = initialState?.country;
  const consumer = initialState?.consumer;
  const flags = initialState?.flags;

  initializeAxios({
    headers: getGlobalEntityIdHeader(headers),
  });

  return (
    <>
      <Head>
        <title>PedidosYa - Ingresar</title>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0"
        />
        <meta
          name="description"
          content="En PedidosYa puedes dejar volar tu imaginación porque ahora todo lo que quieras lo llevamos directo desde tu cabeza a donde estés, ¡en minutos!
¿Lo quieres? ¡Lo tienes! Pide a los mejores restaurantes, haz el pedido del súper, compra la comida para tu mascota, la bebida para los amigos, pide lo que necesites de la farmacia y ¡mucho más!
En simples pasos puedes tener lo que quieras directamente en tu puerta: Descubre, pide y recibe a domicilio con PedidosYa."
        />
        <meta name="theme-color" content="#fa0050" />
        <link rel="shortcut icon" href={favicon.src} />
        <link
          rel="preload"
          as="font"
          href="https://web-commons.pystatic.com/fonts/segma/segma-regular.otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="https://web-commons.pystatic.com/fonts/segma/segma-semibold.otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="https://web-commons.pystatic.com/fonts/segma/segma-bold.otf"
          crossOrigin="anonymous"
        />
      </Head>
      <IntlProvider
        locale={locale}
        key={locale}
        defaultLocale="es"
        textComponent="span"
        messages={messages}
        onError={handleOnErrorTranslations}
      >
        <ThemeProvider theme={theme}>
          <FenixFonts />
          <GlobalStyleSheet />
          <AppProvider initialState={initialState}>
            <QueryClientProvider client={queryClient}>
              <WindowProvider>
                <ConsumerProvider consumer={consumer}>
                  <DeviceProvider>
                    <ToastProvider>
                      <FunWithFlagsProvider flags={flags}>
                        <CountryProvider countryShortName={country?.shortName}>
                          <ErrorBoundary>
                            <Component {...pageProps} />
                          </ErrorBoundary>
                        </CountryProvider>
                      </FunWithFlagsProvider>
                    </ToastProvider>
                  </DeviceProvider>
                </ConsumerProvider>
              </WindowProvider>
            </QueryClientProvider>
          </AppProvider>
        </ThemeProvider>
      </IntlProvider>
    </>
  );
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({
    initialState: PropTypes.shape({}),
    fwfConfig: PropTypes.shape({}),
    trackerConfig: PropTypes.shape({}),
    i18n: PropTypes.shape({
      locale: PropTypes.string,
      messages: PropTypes.shape({}),
    }),
    axios: PropTypes.shape({
      headers: PropTypes.shape({}),
    }),
  }),
};

App.defaultProps = {
  pageProps: {},
};

export default App;
