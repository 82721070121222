import {
  captureException,
  captureMessage,
  setTag,
  setUser,
} from '@sentry/nextjs';
import { isObject } from '../object/object';

/**
 * Track error to sentry
 * @param {string} message
 * @param {Error | string} error
 */
export function logError(message, error) {
  if (error instanceof Error) {
    captureException(error, { extra: { message } });
  } else {
    const messageError = isObject(error) ? error?.message : error;
    captureMessage(message, {
      level: 'error',
      extra: { error: messageError || '' },
      fingerprint: [message],
    });
  }
}

/**
 * Track error event to sentry
 * @param {string} message
 */
export function logEvent(message) {
  captureMessage(message);
}

/**
 * Set the context for the user and the extra tags
 * @param {object} params
 */
export function setUserContext({ user, appVersion }) {
  setUser(user);
  setTag('peya_app_version', appVersion);
}
