const baseColors = {
  red: '#fa0050',
  blue: '#00d9fc',
  lime: '#26fb79',
  limon: '#f5f04f',
  night_blue_1: '#100423',
  white: '#ffffff',
  red_darker_3: '#920147',
  red_darker_2: '#b60747',
  red_darker_1: '#db0c47',
  red_lighter_1: '#ffa19f',
  red_lighter_2: '#fee1e0',
  blue_darker_3: '#002cc1',
  blue_darker_2: '#2656f7',
  blue_darker_1: '#1398fa',
  blue_lighter_1: '#88e9fa',
  blue_lighter_2: '#dbf9fe',
  lime_darker_3: '#005b55',
  lime_darker_2: '#027e75',
  lime_darker_1: '#15bf79',
  lime_lighter_1: '#9ffab3',
  lime_lighter_2: '#e4ffe7',
  atomic_tangerine: '#fdd3bb',
  limon_darker_3: '#fb6f1f',
  limon_darker_2: '#ff9505',
  limon_darker_1: '#fac137',
  limon_lighter_1: '#faf3a2',
  limon_lighter_2: '#fffadb',
  night_blue_2: '#585065',
  night_blue_3: '#b8b4bc',
  deep_purple: '#2b1a46',
  grape: '#413d4b',
  sepia: '#b5816c',
  french_gray_1: '#cac5d0',
  french_gray_2: '#efedf0',
  platinum: '#eae3e3',
  sand: '#f9f6f4',
  rating_a_background: '#d9f4d9',
  rating_a_foreground: '#268210',
  rating_b_background: '#ebf0ca',
  rating_b_foreground: '#657d02',
  rating_c_background: '#f6eece',
  rating_c_foreground: '#7f7304',
  rating_d_background: '#fde6cb',
  rating_d_foreground: '#8d6602',
  grape_75: 'rgba(65, 61, 75, 0.75)',
  grape_50: 'rgba(65, 61, 75, 0.5)',
  grape_20: 'rgba(65, 61, 75, 0.2)',
  sepia_80: 'rgba(181, 129, 108, 0.8)',
  sepia_50: 'rgba(181, 129, 108, 0.5)',
  sepia_40: 'rgba(181, 129, 108, 0.4)',
  sepia_20: 'rgba(181, 129, 108, 0.2)',
  transparent: 'rgba(0, 0, 0, 0)',
  transparent_50: 'rgba(0, 0, 0, 0.5)',
};

export const colors = {
  ...baseColors,

  // Text
  textPrimary: 'rgb(16, 4, 35)',
  textSecondary: '$night_blue_2',

  // Others
  textHint: 'rgb(169, 177, 183)',
  error: 'rgb(194, 0, 24)',
  elementLine: 'rgb(218, 224, 229)',
  elementLightGrey: 'rgb(246, 248, 248)',
  elementInputDisable: 'rgb(221, 226, 229)',

  // DEPRECATED
  corporateWhite: '$white',
  corporateRed: '$red',
  corporateOrange: 'rgb(255, 149, 19)',
  corporateGreen: 'rgb(1, 201, 140)',
  corporateTurquoise: 'rgb(0, 189, 237)',
  corporateBlue: 'rgb(45, 134, 244)',
  corporateLightGrey: 'rgb(244, 245, 245)',
  corporateGray: 'rgb(108, 118, 125)',
  corporateWarning: 'rgb(254, 249, 231)',
};

export default baseColors;
