/**
 * Returns spacing using standard margin or padding syntax.
 *
 * The sizing scale for the args is 2px.
 *
 */
export const getSpacing =
  (...args) =>
  ({ theme }) => {
    let spacing = '';

    args.forEach((token) => {
      spacing += `${theme.space(`space-${token}`)} `;
    });

    return spacing;
  };
