const ROUTES = {
  HOME: '/',
  LOGIN: '/',
  LOGIN_WITH_EMAIL: '/email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/resetPassword',
  PUBLIC_ENVIRONMENT: '/login-env',
  REGISTER_WITH_PHONE: '/sign-up/email',
  SIGN_UP_PROFILE: '/sign-up/profile',
  SIGN_UP_LINK_ACCOUNT: '/sign-up/link-account',
  LOGIN_WITH_OTP_EMAIL: '/otp',
};

export default ROUTES;
