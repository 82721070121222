import styled, { css } from 'styled-components';

const Backdrop = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.color('shape-color-background-secondary')};
    z-index: 1001;
  `,
);

export default Backdrop;
